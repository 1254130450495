import React from 'react'
import { CacheProvider } from '@emotion/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';

export default function RTLInput(props) {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#3ecddb',
            }
        },
        direction: 'rtl',
        typography: {
            "fontFamily": "IRANSansX"
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#f8f9fb !important",
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#f8f9fb !important",
                    }
                }
            }
        }
    });

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <div dir="rtl" >
                    {props.children}
                </div>
            </ThemeProvider>
        </CacheProvider>
    )
}
