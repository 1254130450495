import Tab from "../components/reservation/Tab";
import { useSelector } from 'react-redux';
import { currentTab, updateTab } from '../features/tab/tabSlice';
import Rules from '../components/reservation/Rules';
import PickDate from "../components/reservation/PickDate";
import PatientInfo from '../components/reservation/PatientInfo';
import PatientHealthInfo from '../components/reservation/PatientHealthInfo';
import PatientReview from "../components/reservation/PatientReview";

import TrackingCode from "../components/reservation/TrackingCode";
import { useDispatch } from 'react-redux';
import useQueryParams from "../components/reservation/useQueryParams";
import { useEffect, useState } from "react";
import { getDepartmentInfo } from "../api/routes";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Reservation() {
  const dispatch = useDispatch();
  const activeTab = useSelector(currentTab);
  const { code, ref } = useQueryParams()
  const [departmentInfo, setDepartmentInfo] = useState();

  useEffect(() => {
    getDepartmentInfo((isOk, data) => {
      if (!isOk) return alert(data.message);

      else return setDepartmentInfo(data)
    })
  }, [])
  useEffect(() => {
    if (code && ref === 'afterPayment') {
      dispatch(updateTab(5))
    }
  }, [])
  return (
    <>
      <div className='container'>
        <Nav logo={departmentInfo && departmentInfo[0].logo} />
        <div className="flex flex-col pb-12 pt-20 sm:pt-32 md:pt-40 items-center">
          <section className="h-fit bg-white lg:w-[65%] w-[95%] mt-8 box-shadow rounded-xl py-6 sm:py-12 px-4 md:p-12">
            <Tab />
            {activeTab === 0 && <Rules />}
            {activeTab === 1 && <PickDate />}
            {activeTab === 2 && <PatientInfo />}
            {activeTab === 3 && <PatientHealthInfo />}
            {activeTab === 4 && <PatientReview />}
            {activeTab === 5 && <TrackingCode />}
          </section>
        </div>
      </div>
      <Footer data={departmentInfo && departmentInfo[0]} />
    </>
  );
}

export default Reservation;
