export default function getMinutes(startTime,endTime){

 let s = startTime.split(':');
 let e = endTime.split(':');

 let end = parseInt(e[0])* 60+ parseInt(e[1]);
 let start = parseInt(s[0])*60 + parseInt(s[1]);

 let elapsedMs = end - start;
 
 return elapsedMs ;
}