import React from 'react'
import Marquee from "react-fast-marquee";

export default function Insurance() {
    const insurances = [{ id: 0, name: 'sample-dana.png' }, { id: 1, name: 'sample-day.png' }, { id: 2, name: 'sample-khadamat.png' },
    { id: 3, name: 'sample-sepah.png' }, { id: 4, name: 'sample-tamin.png' }]
    
    return (
        <div className='container pt-6 mt-20' id='insurance'>
            <p className='text-center text-xl font-bold text-[#25406e]'>بیمه های طرف قرارداد</p>
            <Marquee direction="right" speed={20} pauseOnHover={true} gradient={false} className="w-[85%] mx-auto my-0" style={{ direction: 'ltr' }}>
                {insurances.map(insurance => <img loading='lazy' key={insurance.id} src={`/assets/images/${insurance.name}`} alt='insurance' className='mx-12 w-[150px]' />)}
            </Marquee>
        </div>
    )
}
