import { Backdrop, CircularProgress, Divider } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { getDoctorInformation } from '../api/routes';

export default function Profile() {
    const [loading, setLoading] = useState(false);
    const [doctorInfo, setDoctorInfo] = useState({});
    const [searchParams] = useSearchParams();


    useEffect(() => {
        setLoading(true)
        getDoctorInformation(searchParams.get("m"), (isOk, data) => {
            if (!isOk) {
                setLoading(true)
                return alert(data.message);
            }

            else {

                setLoading(false)
                return setDoctorInfo(data[0])
            }
        })
    }, [])
    return (
        <div style={{ width: '100%', padding: "0 1rem" }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <p className='font-bold mt-20 mb-4 mr-4'>مشخصات من:</p>
            <Divider className='w-44 mr-4'/>
            <section className="grid mt-6 mr-4 grid-cols-1 gap-y-6">
                <p>نام پزشک: {doctorInfo.NameDoctor}</p>
                <p>نظام پزشکی: {doctorInfo.MedicalCouncil}</p>
                <p>آدرس مطب: {doctorInfo.AddressDct}</p>
                <p>تلفن مرکز: {doctorInfo.TelDct}</p>
                <p>تخصص: {doctorInfo.Specialty}</p>
                <p>توضیحات: {doctorInfo.Mem}</p>
            </section>
        </div>
    )
}
