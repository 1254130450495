import { Backdrop, CircularProgress, createTheme, TextField, ThemeProvider, Divider } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react'
import { useSearchParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDepartmentInfo, getDoctorPatients } from '../api/routes';
import Nav from '../components/Nav'
import PatientTable from './../components/PatientTable';
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import Profile from '../components/Profile';

export default function DoctorPatients() {
    const { collapseSidebar, collapsed } = useProSidebar();

    const [searchParams] = useSearchParams();
    const [departmentInfo, setDepartmentInfo] = useState([]);
    const [dateFilter, setDateFilter] = useState()
    const [secretDoctor, setSecretDoctor] = useState("");
    const [medDoctor, setMedDoctor] = useState("");
    const [loading, setLoading] = useState(false);
    const [doctorPatients, setDoctorPatients] = useState([]);
    const [showProfile, setShowProfile] = useState(false);
    const [isMobile, setIsMobile] = useState()
    const submitRef = useRef(null);

    useEffect(() => {
        getDepartmentInfo((isOk, data) => {
            if (!isOk) return alert(data.message);

            else return setDepartmentInfo(data)
        })
        if (window.innerWidth < 600) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    useEffect(() => {
        if (searchParams.get("s") && searchParams.get("m")) {
            setLoading(true)
            getDoctorPatients(searchParams.get("m"), searchParams.get("s"), (isOk, data) => {
                if (!isOk) {
                    setLoading(false)
                    return alert(data.message);
                }
                else {
                    if (data.length === 0) {
                        setLoading(false)
                        return toast.error('پزشکی با این مشخصات یافت نشد!')
                    }
                    else {
                        setLoading(false)
                        return setDoctorPatients(data)
                    }
                }
            })
        }
    }, [searchParams.get("s"), searchParams.get("m")])


    const secretDoctorChange = (event) => {
        setSecretDoctor(event.target.value)
    }
    const medDoctorChange = (event) => {
        setMedDoctor(event.target.value)
    }
    const onClickEnter = (event) => {
        if (event.key === 'Enter' && secretDoctor !== '') {
            event.preventDefault();
            event.stopPropagation();
            submitRef.current.click()
        }
    }
    const theme = createTheme({
        direction: 'rtl',
        typography: {
            "fontFamily": "IRANSansX"
        }
    });

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });
    return (
        <>
            <div className={doctorPatients.length === 0 ? 'container' : ""}>
                {doctorPatients.length === 0 && <Nav logo={departmentInfo && departmentInfo[0]?.logo} />}
                <div className={doctorPatients.length === 0 ? "flex pt-28 pb-16 sm:pt-40 sm:pb-[9.5rem] flex-col items-center" : ""}>
                    {doctorPatients.length === 0 ? <section className="h-fit bg-white lg:w-[40%] w-[95%] box-shadow rounded-lg py-6 sm:py-12 px-4 md:p-8">
                        <img src="/assets/images/doctor-il.svg" alt='doctor' className="w-[60%] mx-auto" />
                        <div className='flex flex-col items-center mt-8'>
                            <CacheProvider value={cacheRtl}>
                                <ThemeProvider theme={theme}>
                                    <div dir="rtl" className='flex flex-col items-center flex-wrap'>
                                        <TextField type='tel' variant="outlined" value={medDoctor} onChange={medDoctorChange} label="شماره نظام پزشکی" sx={{ width: "300px" }} />
                                        <TextField onKeyDown={onClickEnter} variant="outlined" type='password' value={secretDoctor} onChange={secretDoctorChange} label="کلمه عبور" className='my-2' sx={{ width: "300px" }} />
                                    </div>
                                </ThemeProvider>
                            </CacheProvider>
                            <Link ref={submitRef} to={secretDoctor === '' ? '/doctor-patients' : `/doctor-patients?m=${medDoctor}&s=${secretDoctor}`}>
                                <button type="button" className='focus:outline-none text-white bg-[#fa3668] font-medium rounded-lg px-8 sm:px-16 py-2.5 mr-4 mt-4'>ادامه</button>
                            </Link>
                            <p className='text-sm sm:text-base mt-4 text-gray-500'>• درصورت داشتن سوال مي توانيد با شماره تلفن 37606010 051 تماس بگيريد.</p>
                        </div>
                    </section> : <main className='flex'>

                        <Sidebar
                            transitionDuration={800}
                            backgroundColor="#F6F7FA"
                            rtl={true}
                            style={{ height: "100vh", width: isMobile === undefined ? 0 : "80px" }}
                            defaultCollapsed={isMobile}
                        >
                            <Menu>
                                <MenuItem
                                    icon={<MenuOutlinedIcon />}
                                    onClick={() => {
                                        collapseSidebar();
                                    }}
                                    style={{ textAlign: "center" }}
                                >
                                    <img src={`data:image/jpeg;base64,${departmentInfo[0]?.logo}`} alt='logo' style={{ width: '100px' }} />
                                </MenuItem>
                                <MenuItem icon={<img src='/assets/images/doctor.svg' alt='doctor' style={{ width: "24px" }} />} style={{ border: "1px solid #3ecdd8", margin: collapsed ? "0" : '0.5rem', borderRadius: "10px", color: "#3ecdd8" }}>{doctorPatients[0].NameDoctorPatient}</MenuItem>
                                <Divider style={{ width: "90%" }} />
                                <MenuItem icon={<HomeOutlinedIcon />} onClick={() => window.location.replace("/")} style={{ marginTop: "1rem" }}>سایت کلینیک</MenuItem>
                                <MenuItem onClick={() => { setDateFilter("week"); setShowProfile(false); }} icon={<TodayOutlinedIcon />}>بیماران هفته جاری</MenuItem>
                                <MenuItem onClick={() => { setDateFilter("month"); setShowProfile(false); }} icon={<DateRangeOutlinedIcon />}>بیماران ماه جاری</MenuItem>
                                <MenuItem onClick={() => { setDateFilter("year"); setShowProfile(false); }} icon={<CalendarMonthOutlinedIcon />}>بیماران سال جاری</MenuItem>
                                <MenuItem onClick={() => setShowProfile(true)} icon={<PersonOutlineOutlinedIcon />}>پروفایل من</MenuItem>
                                <MenuItem onClick={() => window.open("https://gerafi.com", "_blank")} icon={<HelpOutlineOutlinedIcon />}>درباره ما</MenuItem>
                            </Menu>
                        </Sidebar>
                        <section className="pt-4 sm:pr-4 w-full h-screen">
                            {!showProfile ? <PatientTable data={doctorPatients} dateFilter={dateFilter} /> : <Profile />}
                        </section>
                    </main>}
                </div>
            </div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
