import axios from "axios"

export const getAxiosInstanceApi = () => {
	return axios.create({
		baseURL: 'https://api.gy1.ir',
		headers:{
			'x-center-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjZW50ZXJJZCI6IjEwIiwicm9sZSI6InVzZXIiLCJpYXQiOjE2NjIyMzM1ODl9.zwW9BLnO0IgJra-CthbWKWos7WEKYMyR8U_Z3Si2KTs',
		}
	})
}
export const getReportInstanceApi = () => {
	return axios.create({
		baseURL: 'https://report.gy1.ir',
		headers:{
			'x-center-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjZW50ZXJJZCI6IjEwIiwicm9sZSI6InVzZXIiLCJpYXQiOjE2NjIyMzM1ODl9.zwW9BLnO0IgJra-CthbWKWos7WEKYMyR8U_Z3Si2KTs',
		}
	})
}
/* eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjZW50ZXJJZCI6IjEwIiwicm9sZSI6InVzZXIiLCJpYXQiOjE2NjIyMzM1ODl9.zwW9BLnO0IgJra-CthbWKWos7WEKYMyR8U_Z3Si2KTs */

/*  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjZW50ZXJJZCI6IjExIiwicm9sZSI6InVzZXIiLCJpYXQiOjE2NjI0NDA2MjJ9.qAwO6heCfExawDeRrprd3p2yz6lehSPto5K05zFvKjA  */