import React, { useState, useEffect } from 'react'
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import { getGalleryImage } from './../api/routes';

export default function Gallery() {
    const [galleryImages, setGalleryImages] = useState([]);

    useEffect(() => {
        getGalleryImage((isOk, data) => {
            if (!isOk) return alert(data.message);
            else return setGalleryImages(data)
        })
    }, [])
    return (
        <div style={{ background: 'url(/assets/images/commentsbg.svg) 50% 50% / cover no-repeat, rgb(255, 255, 255)' }} id='gallery'>
            <div className='container pt-10 sm:pt-24 pb-20'>
                <section className='flex flex-col items-center'>
                    <p className='text-lg sm:text-2xl text-[#25406e] font-bold'>گالری تصاویر</p>
                    <div className='w-12 h-1 mt-4 bg-[#3ecddb]' />
                </section>
                <section>
                    <Flickity className='w-[95%] md:w-[85%] mx-auto mt-8' options={{ prevNextButtons: true, groupCells: 1, rightToLeft: true, initialIndex: 0, contain: true }}>
                        {galleryImages.map(gallery => <img key={gallery.departmentGalleryId} src={`data:image/jpeg;base64,${gallery.image}`} alt='' loading='lazy' className='w-full h-[300px] sm:h-[700px] ml-4 object-cover rounded-[10px]' />
                        )}
                    </Flickity>
                </section>
            </div>
        </div>
    )
}
