import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react'
import { useSearchParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDepartmentInfo, getPatientReport } from '../api/routes';
import Footer from '../components/Footer'
import Nav from '../components/Nav'

export default function PatientReport() {
    const [searchParams] = useSearchParams();
    const [departmentInfo, setDepartmentInfo] = useState();
    const [codeReception, setCodeReception] = useState("");
    const [loading, setLoading] = useState(false);
    const submitRef = useRef(null)

    // const componentRef = useRef(null);

    useEffect(() => {
        getDepartmentInfo((isOk, data) => {
            if (!isOk) return alert(data.message);

            else return setDepartmentInfo(data)
        })
    }, [])

    useEffect(() => {
        if (searchParams.get("q")) {
            setLoading(true)
            getPatientReport(searchParams.get("q"), (isOk, data) => {
                if (!isOk) {
                    setLoading(false)
                    return alert(data.message);
                }
                else {
                    if (data.length === 0) {
                        setLoading(false)
                        return toast.error('بیمار یافت نشد!')
                    }
                    else return window.location.replace(`http://2.180.27.231:91/patreport.aspx?code=${searchParams.get("q")}`)
                }
            })
        }
    }, [searchParams.get("q")])

/*     const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = useCallback(() => {
        return <button className='focus:outline-none text-white float-left bg-[#2ecd71] mt-4 hover:bg-green-500 focus:ring-green-300 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 w-[120px]'>چاپ جواب</button>;
    }, []); */
    const numberRegex = /^[0-9]*$/g;

    const codeReceptionChange = (event) => {
        if (event.target.value === '')
            return setCodeReception(event.target.value)
        else if (event.target.value.match(numberRegex)) {
            return setCodeReception(event.target.value)
        } else return alert('عدد وارد کنید!')
    }
    const onClickEnter = (event) => {
        if (event.key === 'Enter' && codeReception !== '') {
            event.preventDefault();
            event.stopPropagation();
            submitRef.current.click()
        }
    }
    return (
        <>
            <div className='container'>
                <Nav logo={departmentInfo && departmentInfo[0].logo} />
                <div className="flex pt-28 pb-16 sm:pt-40 sm:pb-[9.5rem] flex-col items-center">
                    <section className="h-fit bg-white lg:w-[40%] w-[95%] box-shadow rounded-lg py-6 sm:py-12 px-4 md:p-12">
                        <p className='font-bold mb-4 text-base sm:text-lg'>براي مشاهده گزارش به نكات ذيل توجه نماييد:</p>
                        <p className='text-sm sm:text-base'>• در كادر پايين شماره پذيرش بيمار را وارد نموده و كليد ادامه را بزنيد.</p>
                        <p className='my-4 text-sm sm:text-base'>• بعد از آماده شدن گزارش، پيامكي حاوي لينك مشاهده گزارش به تلفن همراه بيمار ارسال مي گردد.</p>
                        <p className='text-sm sm:text-base'>• درصورت داشتن سوال مي توانيد با شماره تلفن 37606009 051 تماس بگيريد.</p>
                        <div className='flex items-center mt-8'>
                            <input onKeyDown={onClickEnter} type='tel' value={codeReception} onChange={codeReceptionChange} placeholder="شماره پذیرش" className='flex-1 text-center border rounded-lg h-[44px] px-4' />
                            <Link ref={submitRef} to={codeReception === '' ? '/pr' : `/pr?q=${codeReception}`}>
                                <button type="button" className='focus:outline-none text-white bg-[#fa3668] font-medium rounded-lg px-8 sm:px-12 py-2.5 mr-4'>ادامه</button>
                            </Link>
                        </div>
                    </section>
{/* 
                    {reportData.length !== 0 && <section className='mt-40 mb-12 h-full lg:w-[60%] w-[95%]'>
                        {reportHead && reportData.map((data, index) =>
                            <div ref={componentRef} key={index} className="page printing-config h-full mx-auto py-6 sm:py-12 px-6" style={{ background: `url(${`data:image/png;base64,${Buffer.from(reportHead).toString('base64')}`}) no-repeat center top`, backgroundSize: 'cover' }}>
                                <section className='w-full h-fit mt-60 sm:mt-52'>
                                    <section className='flex flex-col items-end [&>p]:pt-1 [&>p]:text-sm [&>p]:font-bold'>
                                        <p>تاریخ : {data.DateReception}</p>
                                        <p>کد پذیرش : {data.CodeReception}</p>
                                        <p>نام بیمار : {data.NameDoctorPatient}</p>
                                    </section>
                                    <p className='sm:-mt-4 font-bold'>حضور ارجمند دکتر {data.NameDoctor}</p>
                                    <p className='mt-3'>با سلام و احترام</p>
                                    <p className='mt-6'>{data.TextPlainReport}</p>
                                    <div className='flex justify-end'>
                                        <img src={`data:image/png;base64,${Buffer.from(doctorSign).toString('base64')}`} alt='sign' className='mt-4 mb-24' />
                                    </div>
                                </section>
                            </div>)}
                        <ReactToPrint
                            content={reactToPrintContent}
                            documentTitle={'جواب دهی بیماران'}
                            onBeforePrint={() => setLoading(false)}
                            removeAfterPrint
                            onBeforeGetContent={() => setLoading(true)}
                            trigger={reactToPrintTrigger}
                        />
                    </section>} */}
                </div>
            </div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Footer data={departmentInfo && departmentInfo[0]} />
        </>
    )
}
