import React, { useEffect, useState } from 'react'
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import FrequencyQuestions from '../components/FrequencyQuestions';
import Insurance from '../components/Insurance';
import Nav from '../components/Nav'
import AboutUs from './../components/AboutUs';
import { getDepartmentInfo, getScenario } from './../api/routes';
import { Backdrop, CircularProgress } from '@mui/material';
import Gallery from '../components/Gallery';
import Services from '../components/Services';
import { MdLocationOn } from 'react-icons/md';
import moment from 'jalali-moment';
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";
import { Link } from 'react-router-dom';

export default function Home() {
    const [departmentInfo, setDepartmentInfo] = useState();
    const [open, setOpen] = useState(false);
    const [scenarios, setScenarios] = useState([]);

    const m = moment()
    m.locale('fa');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        handleOpen()
        getDepartmentInfo((isOk, data) => {
            if (!isOk) {
                handleClose()
                return alert(data.message);
            }
            else {
                handleClose()
                return setDepartmentInfo(data)
            }
        })
        sessionStorage.clear()
    }, [])
    useEffect(() => {
        getScenario(m?.locale('fa')?.format('YYYYMMDD'), (isOk, data) => {
            if (!isOk) return alert(data.message);
            else return setScenarios(data)
        })
    }, [])

    let times = []
    for (let i = 0; i < scenarios.length; i++) {
        times.push({ reservationDate: scenarios[i].reservationDate, times: [{ from: scenarios[i]?.startTime, to: scenarios[i]?.endTime }] })
    }

    let newScenarios = [];
    times.forEach(function (item) {
        let existing = newScenarios.filter(function (v, i) {
            return v.reservationDate === item.reservationDate;
        });

        if (existing.length) {
            let existingIndex = newScenarios.indexOf(existing[0]);
            newScenarios[existingIndex].times = newScenarios[existingIndex].times.concat(item.times);
        } else {
            if (typeof item.times == 'string')
                item.times = [item.times];
            newScenarios.push(item);
        }
    });



    return (
        <>
            <div style={{ background: 'url(/assets/images/headerbg.svg) 0% 57.79% / cover no-repeat', inset: '-40% 0px' }}>
                <header className='container'>
                    <Nav logo={departmentInfo && departmentInfo[0].logo} />
                    <div className='h-[95px]' />
                    <section className='flex justify-around items-center mt-1 sm:mt-8 flex-wrap-reverse'>
                        {departmentInfo && departmentInfo[0] && <img loading='lazy' src={departmentInfo ? `data:image/jpeg;base64,${departmentInfo[0].headerImage}` : ''} alt='dr' className='xl:w-auto w-[80%] mt-10 lg:mt-0 sm:w-[400px] xl:h-[850px]' />}
                        <div className='w-[500px] mt-6 sm:mt-10 mr-4 lg:mt-0 lg:mr-0'>
                            <p className='text-sm hidden sm:block text-center sm:text-start sm:text-lg text-[#01010161]'>{departmentInfo ? departmentInfo[0].smallTitle : ''}</p>
                            <p className='text-xl text-center px-4 sm:px-0 sm:text-start lg:text-4xl xl:text-3xl sm:mt-5 xl:leading-[50px]'>{departmentInfo ? departmentInfo[0].bigTitle : ''}</p>

                            <Flickity className='w-full -pr-8' options={{ prevNextButtons: true, groupCells: 1, pageDots: false, rightToLeft: true, initialIndex: 0 }}>
                                {newScenarios.map((scenario, index) => <Link to='/reservation'><section key={index} className='flex sm:w-[200px] sm:h-[200px] w-[170px] h-[150px] overflow-hidden items-center flex-col bg-gray-50 ring-2 ring-[#3ecddb] mt-6 sm:mt-12 mb-8 ml-8 px-4 pt-6 rounded-[35px] sm:rounded-[45px] shadow-xl'>
                                    <p className='mb-2 font-bold text-[#fa3668]'>{moment(`${String(scenario.reservationDate).slice(0, 4)}/${String(scenario.reservationDate).slice(4, 6)}/${String(scenario.reservationDate).slice(6)}`, 'jYYYY/jM/jD').locale('fa').format('LLLL').slice(0, -11)}</p>
                                    {scenario.times.map((time, index2) => <p key={index2} className='mt-[2px] sm:mt-1 text-xs sm:text-sm'>{`از ساعت ${time.from} الی ${time.to}`}</p>)}
                                </section></Link>)}
                            </Flickity>
                            <p className='text-center'>اولین نوبت آزاد در <span className='text-[#3ecddb] font-bold'><Link to='/reservation'> بخش نوبت دهی</Link></span></p>
                            <section className='flex w-full justify-center'>

                                <a href='https://www.google.com/maps/dir//%DA%A9%D9%84%DB%8C%D9%86%DB%8C%DA%A9+%D8%B3%D9%88%D9%86%D9%88%DA%AF%D8%B1%D8%A7%D9%81%DB%8C+%D9%88+%D8%B1%D8%A7%D8%AF%DB%8C%D9%88%D9%84%D9%88%DA%98%DB%8C+%D8%AF%DA%A9%D8%AA%D8%B1+%D8%BA%D9%81%D8%A7%D8%B1%DB%8C%D8%8C+Razavi+Khorasan+Province,+Mashhad,+Ferdowsi+Blvd,+8HF3%2BWJC,+Iran%E2%80%AD/@36.3245752,59.5543988,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3f6c934a1e300093:0x4245bc2cc8de4292!2m2!1d59.5540088!2d36.3247913' target='_blank' rel="noopener noreferrer">
                                    <button className='bg-[#e2e3e6] hidden lg:flex justify-center items-center shadow-sm hover:bg-[#fa3668] transition rounded-full sm:w-[268px] w-[230px] h-[41px] sm:h-[54px] text-[#30292ba6] hover:text-white text-sm font-bold mt-11'><MdLocationOn className='ml-1 -scale-x-100' size='18px' />مسیردهی در گوگل مپ</button>
                                </a>
                            </section>
                        </div>
                    </section>
                </header >
            </div >
            <AboutUs aboutText={departmentInfo && departmentInfo[0].aboutText} aboutImage={departmentInfo && departmentInfo[0].aboutImage} />
            <Services />
            <Gallery />
            <FrequencyQuestions />
            <ContactUs data={departmentInfo && departmentInfo[0]} />
            <Insurance />
            <Footer data={departmentInfo && departmentInfo[0]} />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
