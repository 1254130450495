import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { VscMenu } from 'react-icons/vsc'
import { Divider, Drawer, Menu, MenuItem, } from '@mui/material';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

export default function Nav({ logo }) {
  const [navBg, setNavBg] = useState(false);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);

  const changeNavBg = () => {
    window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNavBg);
    return () => {
      window.removeEventListener('scroll', changeNavBg);
    }
  }, [])
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setHamburgerMenu(open);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <nav onScroll={changeNavBg} className={`flex px-4 md:px-0 justify-between md:justify-around items-center fixed top-0 left-0 right-0 z-[1000] w-full transition-all duration-200 ${navBg ? 'bg-[#eff9fa] shadow-lg py-3' : 'pt-[30px]'}`}>
      <ul className='hidden lg:flex items-center [&>:not(:last-child)]:ml-7 hover:[&>*]:text-[#3ecddb] hover:[&>*]:border-b hover:[&>*]:border-b-[#3ecddb]'>
        <Link to='/'>
          <li>خانه</li>
        </Link>
        <a href='/#about'>
          <li>درباره کلینیک</li>
        </a>
        <a href='/#services'>
          <li className='flex items-center'>خدمات</li>
        </a>
        <a href='/#gallery'>
          <li>گالری تصاویر</li>
        </a>
        <a href='/#contactUs'>
          <li>تماس با ما</li>
        </a>
        <a href='/#insurance'>
          <li>بیمه های طرف قرارداد</li>
        </a>
      </ul>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
      <VscMenu size='18px' className='lg:hidden block ml-1' onClick={toggleDrawer(true)} />

      <section className='flex items-center'>
        <a href={window.location.pathname.includes('/reservation') ? '/' : '/reservation'} onClick={() => sessionStorage.clear()}>
          <button className={`${window.location.pathname === '/reservation' ? 'bg-[#e2e3e6] shadow-xl' : 'bg-[#3ecddb] text-white button-shadow hover:bg-[#fa3668]'} transition rounded-full w-[100px] sm:w-[120px] h-[41px] text-sm font-bold`}>{window.location.pathname.includes('/reservation') ? 'خانه' : 'نوبت دهی'}</button>
        </a>
        <Link to='/pr'>
          <button className='bg-[#fa3668] transition rounded-full w-[120px] sm:w-[140px] h-[41px] text-white text-sm font-bold mx-1 sm:mx-2'>جواب دهی بیماران</button>
        </Link>
        <Link to='/doctor-patients'>
          <button className='bg-[#e2e3e6] transition rounded-full w-[120px] sm:w-[140px] h-[41px] shadow hidden lg:block text-[#30292ba6] text-sm font-bold'>پرتال پزشکان</button>
        </Link>
        <Link to='/'>
          {logo && <img loading='lazy' src={`data:image/jpeg;base64,${logo}`} alt='' className='w-[105px] sm:w-[140px] object-contain' />}
        </Link>
      </section>
      <Drawer anchor={'right'} open={hamburgerMenu} onClick={toggleDrawer(false)} sx={{ '& .MuiPaper-root': { padding: '1rem', width: { xs: '70%', sm: '50%' } } }}>
        {logo && <img loading='lazy' src={`data:image/jpeg;base64,${logo}`} alt='' className='w-[105px] object-contain md:hidden block mb-4 mx-auto' />}
        <Divider />
        <Link to='/'>
          <p className='text-sm mt-4'>خانه</p>
        </Link>
        <a href='/#about'>
          <p className='text-sm mt-4'>درباره کلینیک</p>
        </a>
        <a href='/#services'>
          <p className='text-sm mt-4'>خدمات</p>
        </a>
        <a href='/#gallery'>
          <p className='text-sm mt-4'>گالری تصاویر</p>
        </a>
        <a href='/#contactUs'>
          <p className='text-sm mt-4'>تماس با ما</p>
        </a>
        <a href='/#insurance'>
          <p className='text-sm my-4'>بیمه های طرف قرارداد</p>
        </a>
        <Divider />
        <section className='flex flex-col lg:hidden mt-4'>
          <Link to='/reservation' onClick={() => sessionStorage.clear()}>
            <button className='bg-[#3ecddb] button-shadow hover:bg-[#fa3668] transition rounded-full w-full h-[41px] text-white text-xs font-bold'>نوبت دهی</button>
          </Link>
          <Link to='/pr'>
            <button className='bg-[#fa3668] transition rounded-full w-full h-[41px] text-white text-xs font-bold mt-2'>جواب دهی بیماران</button>
          </Link>
          <Link to='/doctor-patients'>
            <button className='bg-[#e2e3e6] transition rounded-full w-full h-[41px] text-xs font-bold mt-2'>پرتال پزشکان</button>
          </Link>
        </section>
      </Drawer>
    </nav>
  )
}
