import axios from "axios";
import cachios from "cachios";

const cacheAxiosInstance = axios.create({
	baseURL: 'https://api.gy1.ir',
	headers:{
		'x-center-token':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjZW50ZXJJZCI6IjEwIiwicm9sZSI6InVzZXIiLCJpYXQiOjE2NjIyMzM1ODl9.zwW9BLnO0IgJra-CthbWKWos7WEKYMyR8U_Z3Si2KTs',
	}
})

export const cachiosInstance = cachios.create(cacheAxiosInstance);
