import { useSelector } from 'react-redux';
import { currentTab } from '../../features/tab/tabSlice';

const Tab = () => {
    const activeTab = useSelector(currentTab);

    const tabs = [{ id: 0, name: 'قوانین و مقررات' }, { id: 1, name: 'انتخاب تاریخ' }, { id: 2, name: 'اطلاعات بیمار' }, { id: 3, name: 'اطلاعات سلامت' }, { id: 4, name: 'تایید نهایی' }, { id: 5, name: 'کد پیگیری' }];

    return (
        <div className='relative'>
            <div className='bg-[#f3f3f3] hidden sm:flex items-center flex-wrap mx-auto rounded-[7px] justify-center [&>*]:text-center py-3 md:py-0 [&>*]:my-2 sm:[&>*]:my-0 [&>*]:py-1 text-[13px] md:h-11 mb-10'>
                {tabs.map((tab) =>
                    <p key={tab.id} className={`${activeTab === tab.id ? 'selected-tab' : ''} w-[116px]`}>{tab.name}</p>
                )}
            </div>
            <div className='flex sm:hidden items-center justify-between mb-4'>
                {tabs.map((tab, index) =>
                    <p key={tab.id} className={`${activeTab === tab.id ? 'bg-[#3ecddb] text-white' : 'bg-[#f3f3f3]'} z-10 w-8 h-8 rounded-full flex justify-center items-center`}>{index + 1}</p>
                )}
            </div>
            <hr className='my-4 top-0 h-4 w-full sm:hidden absolute' />
        </div>
    )
}
export default Tab;