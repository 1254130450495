import { useEffect, useState } from "react";
import { addDeposit, addPatientHealthInfo, reserveWithOutPayment, addPatientInformation, addPatientsFiles, getRoles } from '../../api/routes';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import moment from "jalali-moment";
import { updateTab } from "../../features/tab/tabSlice";
import { useDispatch } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import seperate from "./seperate";

export default function PatientReview() {
  const m = moment();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const closeLoading = () => {
    setLoading(false);
  };
  const openLoading = () => {
    setLoading(true);
  };
  const [roles, setRoles] = useState([])
  const [patientId, setPatientId] = useState();

  useEffect(() => {
    getRoles((isOk, data) => {
      if (!isOk) return toast.error(data.message);
      else return setRoles(data);
    })
  }, [])

  function urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      })
    );
  }
  useEffect(() => {
    if (patientId) {
      openLoading()
      const data = {
        patientId
      }
      addDeposit(data, (isOk, data) => {
        if (!isOk) {
          closeLoading()
          return toast.error(data.message);
        }
        else {
          closeLoading()
          return window.location.replace(data);;
        }
      })
    }
  }, [patientId])

  const nextLevel = () => {
    if (sessionStorage.getItem('userInformation') !== null && !patientId) {
      openLoading();
      const data = JSON.parse(sessionStorage.getItem('userInformation') || '')?.value
      addPatientInformation(data, (isOk, submitedPatient) => {
        if (!isOk) {
          closeLoading()
          return toast.error(submitedPatient.message ?? submitedPatient);
        }
        else {
          if (sessionStorage.getItem('image') !== null) {
            urltoFile(JSON.parse(sessionStorage.getItem('image') || '')?.value, 'file.jpeg', 'image/jpeg')
              .then(function (file) {
                const formData = new FormData();
                formData.append("file", new File([file], "file.jpeg", { type: "text/json;charset=utf-8" }));
                formData.append('patientId', submitedPatient[0].patientId);
                formData.append('uploadAt', m?.locale('fa')?.format('YYYYMMDD'));

                addPatientsFiles(formData, (isOk, data) => {
                  if (!isOk) {
                    closeLoading()
                    return toast.error(data.message);
                  } else {
                    if (sessionStorage.getItem('healthInformation') !== null) {
                      const healthInformationData = JSON.parse(sessionStorage.getItem('healthInformation') || '')?.value
                      addPatientHealthInfo({ healthInformationData, patientId: submitedPatient[0].patientId }, (isOk, data) => {
                        if (!isOk) {
                          closeLoading()
                          return toast.error(data.message);
                        } else {
                          setPatientId(submitedPatient[0].patientId)
                          closeLoading();
                        }
                      })
                    } else {
                      setPatientId(submitedPatient[0].patientId)
                      closeLoading();
                    }
                  }
                })
              });
          } else {
            if (sessionStorage.getItem('healthInformation') !== null) {
              const healthInformationData = JSON.parse(sessionStorage.getItem('healthInformation') || '')?.value
              addPatientHealthInfo({ healthInformationData, patientId: submitedPatient[0].patientId }, (isOk, data) => {
                if (!isOk) {
                  closeLoading()
                  return toast.error(data.message);
                } else {
                  setPatientId(submitedPatient[0].patientId);
                  closeLoading();
                }
              })
            } else {
              setPatientId(submitedPatient[0].patientId);
              closeLoading();
            }
          }
        }
      })
    }
    else if (patientId) {
      openLoading()
      const data = {
        patientId
      }
      addDeposit(data, (isOk, data) => {
        if (!isOk) {
          closeLoading()
          return toast.error(data.message);
        }
        else {
          closeLoading()
          return window.location.replace(data);
        }
      })
    }
  }

  useEffect(() => {
    const allSesstionStorage = Object.keys(sessionStorage).map((key) => ({ name: key, value: JSON.parse(sessionStorage[key]).value, expiredAt: JSON.parse(sessionStorage[key]).expiredAt }));
    allSesstionStorage.map(item => {
      let expiredAt = new Date(item.expiredAt);
      if (expiredAt > new Date()) {
        return item.value;
      } else {
        sessionStorage.clear();
        return dispatch(updateTab(0));
      }
    })
  }, [])
  /*  */
  return (
    <div>
      <p>آقا / خانم <span className="font-semibold">{JSON.parse(sessionStorage.getItem("fullname") || '')?.value}</span></p>
      <br />
      <p>نوبت: <span className="font-semibold">{JSON.parse(sessionStorage.getItem("service") || '')?.value}</span></p>
      <p className="py-3">تاریخ ویزیت: <span className="font-semibold">{JSON.parse(sessionStorage.getItem("date") || '')?.value}</span></p>
      <p>زمان تقریبی ویزیت: <span className="font-semibold">{JSON.parse(sessionStorage.getItem("time") || '')?.value}</span></p>
      <p className="pt-3">مبلغ قابل پرداخت: <span className="font-semibold text-[#fa3668]">{seperate(JSON.parse(sessionStorage.getItem("price") || '')?.value)} تومان</span></p>
      <section className='text-[#83878a] mt-8 text-[13px]'>
        {(roles.length > 0 && roles[0]?.DescriptionVerify) && parse(roles[0]?.DescriptionVerify)}
      </section>

      <section className='flex justify-end mt-4'>
        <button type="button" onClick={() => !loading && nextLevel()} className='focus:outline-none text-white  bg-[#2ecd71] hover:bg-green-500 focus:ring-green-300 focus:ring-4 font-medium rounded-lg px-6 py-2.5'>پرداخت</button>
      </section>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
