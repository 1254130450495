import { createSlice } from '@reduxjs/toolkit';
import setToSession from '../../components/reservation/setToSession';

const initialState = {
  value: sessionStorage.getItem("tab") ? Number(JSON.parse(sessionStorage.getItem("tab") || '').value) : 0,
  status: 'idle',
};


export const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    updateTab: (state, action) => {
      setToSession("tab",String(action.payload),30);
      state.value = action.payload;
    },
  },
});

export const { updateTab } = tabSlice.actions;

export const currentTab = (state) => state.tab.value;

export default tabSlice.reducer;
