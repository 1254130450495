import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { Divider, InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { FormControl } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTab } from '../../features/tab/tabSlice';
import { getInsurance } from '../../api/routes';
import { toast } from 'react-toastify';
import setToSession from './setToSession';

const theme = createTheme({
    direction: 'rtl',
    typography: {
        "fontFamily": "IRANSansX"
    }
});

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const PatientInfo = () => {
    const dispatch = useDispatch();
    const [insurance, setInsurance] = useState([]);
    const [isImageUpload, setIsImageUpload] = useState(false)
    const [mainInsurance, setMainInsurance] = useState([]);
    const [information, setInformation] = useState({
        name: '',
        lastName: '',
        nationalCode: '',
        gender: '',
        age: '',
        weight: '',
        address: '',
        groupName: '',
        phoneNumber: '',
        tel: '',
        insurance: '',
        mainInsurance: ''
    })
    useEffect(() => {
        getInsurance(1, (isOk, data) => {
            if (!isOk) return toast.error(data.message);
            else return setInsurance(data);
        })
        getInsurance(0, (isOk, data) => {
            if (!isOk) return toast.error(data.message);
            else return setMainInsurance(data);
        })
    }, [])

    const file = useRef(null);

    const persianTextRegex = /^[\u0621-\u0628\u062A-\u063A\u0641-\u0642\u0644-\u0648\u064E-\u0651\u0655\u067E\u0686\u0698\u06A9\u06AF\u06BE\u06CC\u0629\u0643\u0649-\u064B\u064D\u06D5\u0020\u2000-\u200F\u2028-\u202F]+$/g
    const nationalCodeRegex = /^(?!(\d)\1{9})\d{10}$/g
    const phoneNumberRegex = /^(|0098|98|0)?9\d{9}$/g;
    // const telRegex = /^0\d{2,3}\d{8}$/g;

    const nameChange = (event) => {
        if (event.target.value === '')
            return setInformation((prev) => ({
                ...prev,
                name: event.target.value
            }))
        else if (event.target.value.match(persianTextRegex)) {
            return setInformation((prev) => ({
                ...prev,
                name: event.target.value
            }))
        } else return alert('نام باید فارسی باشد!')
    }
    const lastNameChange = (event) => {
        if (event.target.value === '')
            return setInformation((prev) => ({
                ...prev,
                lastName: event.target.value
            }))
        else if (event.target.value.match(persianTextRegex)) {
            return setInformation((prev) => ({
                ...prev,
                lastName: event.target.value
            }))
        } else return alert('نام خانوادگی باید فارسی باشد!')
    }
    /*     const addressChange = (event) => {
            if (event.target.value === '')
                return setInformation((prev) => ({
                    ...prev,
                    address: event.target.value
                }))
            else if (event.target.value.match(persianTextRegex)) {
                return setInformation((prev) => ({
                    ...prev,
                    address: event.target.value
                }))
            } else return alert('آدرس باید فارسی باشد!')
        } */
    const nationCodeChange = (event) => {
        if (event.target.value.length < 10)
            return setInformation((prev) => ({
                ...prev,
                nationalCode: event.target.value.trim()
            }))
        else if (event.target.value.length === 10 && event.target.value.match(nationalCodeRegex)) {
            return setInformation((prev) => ({
                ...prev,
                nationalCode: event.target.value.trim()
            }))
        } else return alert('کد ملی 10 رقم است!')
    }
    const phoneNumberChange = (event) => {
        if (event.target.value.length < 11)
            return setInformation((prev) => ({
                ...prev,
                phoneNumber: event.target.value.trim()
            }))
        else if (event.target.value.length === 11 && event.target.value.match(phoneNumberRegex)) {
            return setInformation((prev) => ({
                ...prev,
                phoneNumber: event.target.value.trim()
            }))
        } else if (event.target.value.length > 11) {
            alert('شماره موبایل بیشتر از 11 رقم است!')
        } else return alert('شماره موبایل نامعتبر هست!')
    }
    /*     const weightChange = (event) => {
            if (event.target.value <= 100) {
                setInformation((prev) => ({ ...prev, weight: event.target.valueAsNumber }))
            } else {
                setInformation((prev) => ({ ...prev, weight: '' }))
                return alert('برای وزن های بالاتر 100 کیلوگرم به مرکز مراجعه کنید!')
            }
        }
        const ageChange = (event) => {
            if (event.target.value <= 70) {
                setInformation((prev) => ({ ...prev, age: event.target.valueAsNumber }))
            } else {
                setInformation((prev) => ({ ...prev, age: '' }))
                return alert('برای سن های بالاتر 70 به مرکز مراجعه کنید!')
            }
        }
        const telChange = (event) => {
            if (event.target.value.length < 11 || event.target.value.length < 12)
                return setInformation((prev) => ({
                    ...prev,
                    tel: event.target.value.trim()
                }))
            else if ((event.target.value.length === 11 || event.target.value.length === 12) && event.target.value.match(telRegex)) {
                return setInformation((prev) => ({
                    ...prev,
                    tel: event.target.value.trim()
                }))
            } else if (event.target.value.length > 12) {
                alert('شماره تلفن بیشتر از 12 رقم است!')
            } else return alert('شماره تلفن نامعتبر هست!')
        } */
    const nextLevel = () => {
        console.log(information.age)
        if (information.name === null || information.name === '') {
            return toast.error('نام را وارد کنید!')
        } else if (information.lastName === null || information.lastName === '') {
            return toast.error('نام خانوادگی را وارد کنید!')
        } else if (information.nationalCode === '' || !information.nationalCode.match(nationalCodeRegex)) {
            return toast.error('کد ملی را وارد کنید!')
        }
        // else if (information.gender === '') {
        //     return toast.error('جنسیت را وارد کنید!')
        // }
        // else if (!!information.age === false || information.age === '') {
        //     return toast.error('سن را وارد کنید!')
        // }
        // else if (!!information.weight === false|| information.weight === '') {
        //     return toast.error('وزن را وارد کنید!')
        // }
        else if (information.phoneNumber === '' || !information.phoneNumber.match(phoneNumberRegex)) {
            return toast.error('شماره موبایل را وارد کنید!')
        }
        // else if (information.tel !== '' && !information.tel.match(telRegex)) {
        //     return toast.error('شماره تلفن را وارد کنید!')
        // }
        else if (information.mainInsurance === '') {
            return toast.error('نوع بیمه درمانی را انتخاب کنید!')
        } else {
            const data = {
                firstName: information.name,
                lastName: information.lastName,
                nationalCode: information.nationalCode,
                gender: information.gender,
                age: information.age,
                weight: information.weight,
                mobileNumber: information.phoneNumber,
                tel: information.tel === '' ? undefined : information.tel,
                address: information.address === '' ? undefined : information.address,
                mainInsuranceId: information.mainInsurance.idInsurance,
                insuranceId: information.insurance === '' ? undefined : information.insurance.idInsurance,
                reservationDate: JSON.parse(sessionStorage.getItem("date") || '')?.value?.split('/').join(''),
                reservationTime: JSON.parse(sessionStorage.getItem("time") || '')?.value,
                groupName: JSON.parse(sessionStorage.getItem("service") || '')?.value,
                subRequestGroupId: JSON.parse(sessionStorage.getItem("subRequestGroupId") || '')?.value,
                reservedSubTimesId: JSON.parse(sessionStorage.getItem("reservedSubTimesId") || '')?.value,
                requestGroupId: JSON.parse(sessionStorage.getItem("requestGroupId") || '')?.value
            }
            setToSession("fullname", information.name + ' ' + information.lastName, 30);
            setToSession("price", (information.insurance && parseInt(information.insurance.price) !== 0) ? Math.abs(parseInt(information.insurance.price) + parseInt(JSON.parse(sessionStorage.getItem("price") || '')?.value)) : Math.abs(parseInt(information.mainInsurance.price) + parseInt(JSON.parse(sessionStorage.getItem("price") || '')?.value)), 30);
            setToSession("userInformation", data, 30);

            dispatch(updateTab(3));
        }
    }
    const uploadImage = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files[0].size / 1024 / 1024 < 4) {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (event) => {
                    setToSession("image", event.target.result, 30);
                    setIsImageUpload(true)
                };
            } else toast.error("حجم فایل باید کمتر از 4 مگابایت باشد");
        }
    }

    useEffect(() => {
        const allSesstionStorage = Object.keys(sessionStorage).map((key) => ({ name: key, value: JSON.parse(sessionStorage[key]).value, expiredAt: JSON.parse(sessionStorage[key]).expiredAt }));
        allSesstionStorage.map(item => {
            let expiredAt = new Date(item.expiredAt);
            if (expiredAt > new Date()) {
                return item.value;
            } else {
                sessionStorage.clear();
                return dispatch(updateTab(0));
            }
        })
    }, [])

    return (
        <div>
            <section className='flex flex-col md:flex-row justify-center flex-wrap [&>*]:my-2 md:[&>*]:mx-8 text-sm'>
                <p className='text-gray-500'>نوع خدمت : <span className='text-black font-semibold'>{JSON.parse(sessionStorage.getItem("service") || '')?.value}</span></p>
                <p className='text-gray-500'>تاریخ نوبت : <span className='text-black font-semibold'>{JSON.parse(sessionStorage.getItem("date") || '')?.value}</span></p>
                <p className='text-gray-500'>ساعت نوبت : <span className='text-black font-semibold'>{JSON.parse(sessionStorage.getItem("time") || '')?.value}</span></p>
            </section>
            <Divider style={{ margin: '1rem 0' }} />
            <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={theme}>
                    <div dir="rtl" className='flex flex-wrap items-center justify-between [&>div]:w-full sm:[&>div]:w-auto [&>div]:my-2 sm:[&>div]:my-4'>
                        <TextField label="نام" required value={information.name} onChange={nameChange} variant="outlined" />
                        <TextField label="نام خانوادگی" required value={information.lastName} onChange={lastNameChange} variant="outlined" />
                        <TextField label="کدملی" type='tel' className='ltr-input' required value={information.nationalCode} onChange={nationCodeChange} variant="outlined" />
                        {/* <FormControl required className="w-full sm:w-[217px]">
                            <InputLabel>جنسیت</InputLabel>
                            <Select label="جنسیت" value={information.gender} onChange={(event) => setInformation((prev) => ({ ...prev, gender: event.target.value }))}>
                                <MenuItem value={0}>مرد</MenuItem>
                                <MenuItem value={1}>زن</MenuItem>
                            </Select>
                        </FormControl> */}
                        {/* <TextField label="سن" InputProps={{ inputProps: { min: 0 } }} required type='number' value={information.age} onChange={ageChange} variant="outlined" /> */}
                        {/* <TextField label="وزن" InputProps={{ inputProps: { min: 0 } }} required type='number' value={information.weight} onChange={weightChange} variant="outlined" /> */}
                        <TextField label="موبایل" required type='tel' className='ltr-input' value={information.phoneNumber} onChange={phoneNumberChange} variant="outlined" />
                        {/* <TextField label="تلفن" type='tel' value={information.tel} onChange={telChange} variant="outlined" /> */}

                        {/* <TextField style={{ width: '100%' }} label="آدرس" value={information.address} onChange={addressChange} variant="outlined" /> */}
                    </div>
                </ThemeProvider>
            </CacheProvider>
            <Divider style={{ margin: '1rem 0' }} />
            <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={theme}>
                    <div dir="rtl" className='flex flex-wrap items-center justify-between [&>div]:my-2 sm:[&>div]:my-4'>
                        <FormControl required className="w-full sm:w-[48%] md:w-[217px]">
                            <InputLabel>نوع بیمه درمانی</InputLabel>
                            <Select label="نوع بیمه درمانی" value={information.mainInsurance} onChange={(event) => setInformation((prev) => ({ ...prev, mainInsurance: event.target.value }))}>
                                {mainInsurance && mainInsurance.map((item) =>
                                    <MenuItem key={item.idInsurance} value={item}>{item.InsuranceName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl className="w-full sm:w-[48%] md:w-[217px]">
                            <InputLabel>بیمه تکمیلی</InputLabel>
                            <Select label="بیمه تکمیلی" value={information.insurance} onChange={(event) => setInformation((prev) => ({ ...prev, insurance: event.target.value }))}>
                                {insurance && insurance.map((item) =>
                                    <MenuItem key={item.idInsurance} value={item}>{item.InsuranceName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <input ref={file} type="file" accept="image/*" style={{ display: 'none' }} onChange={uploadImage} />
                        <div className='rounded flex px-4 justify-between w-full md:w-[50%] h-[56px] border border-gray-300 items-center' onClick={() => file?.current?.click()}>
                            <p className='text-[#00000099]'>تصویر نسخه یا معرفی نامه</p>
                            {isImageUpload ? <p className='text-green-400'>آپلود شد</p> : <p className='text-gray-400'>آپلود کنید</p>}
                        </div>
                    </div>
                </ThemeProvider>
            </CacheProvider>
            <p className='text-red-500 text-xs font-bold mt-2'>* اطلاعات خود را با دقت وارد کنید پس از زدن دکمه ادامه امکان بازگشت وجود ندارد!</p>
            <section className='flex justify-end mt-8 sm:mt-4'>
                <button type="button" onClick={nextLevel} className='focus:outline-none text-white  bg-[#2ecd71] hover:bg-green-500 focus:ring-green-300 focus:ring-4 font-medium rounded-lg w-[120px] text-sm px-5 py-2.5'>ادامه</button>
            </section>

        </div>
    )
}
export default PatientInfo;