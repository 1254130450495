import React from 'react'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getDeposit, getDepartmentInfo, getRoles } from '../../api/routes';
import useQueryParams from './useQueryParams';
import { useState } from 'react';
import seperate from './seperate'
import parse from 'html-react-parser';
import ReactToPrint from "react-to-print";
import { useCallback } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export default function TrackingCode() {
    const { code, ref } = useQueryParams()
    const [deposit, setDeposit] = useState({})
    const [roles, setRoles] = useState([])
    const componentRef = React.useRef(null);
    const [departmentInfo, setDepartmentInfo] = useState()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (code && ref === 'afterPayment') {
            getDeposit(code, (isOk, data) => {
                if (!isOk) return toast.error(data.message);
                else {
                    setDeposit(data[0]);
                }
            })

        }
    }, [code, ref])

    useEffect(() => {
        getRoles((isOk, data) => {
            if (!isOk) return toast.error(data.message);
            else return setRoles(data);
        })
        getDepartmentInfo((isOk, data) => {
            if (!isOk) return toast.error(data.message);
            else return setDepartmentInfo(data);
        })
    }, [])

    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = useCallback(() => {
        return <button className='focus:outline-none text-white  bg-[#2ecd71] hover:bg-green-500 focus:ring-green-300 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 w-[120px] float-left mt-6'>چاپ نوبت</button>;
    }, []);

    return (
        <>
            {!JSON.parse(sessionStorage.getItem("price") || '')?.value > 0 || deposit.success ? <>
                <div ref={componentRef} className='sm:px-4'>
                    <section className='w-full h-24 mb-6 flex items-center justify-center'>
                        {departmentInfo && departmentInfo[0].logo && <img loading='lazy' src={`data:image/jpeg;base64,${departmentInfo[0].logo}`} alt='logo' className='w-[105px]' style={{ objectFit: 'contain' }} />}
                    </section>
                    <section className='grid grid-cols-1 sm:grid-cols-2 gap-y-5 sm:gap-y-6 px-3 sm:text-base text-sm'>
                        <p>تاریخ نوبت : {JSON.parse(sessionStorage.getItem("date") || '')?.value} - {JSON.parse(sessionStorage.getItem("time") || '')?.value}</p>
                        <p>نوع خدمت : {JSON.parse(sessionStorage.getItem("service") || '')?.value}</p>
                        <p>نام و نام خانوادگی : {JSON.parse(sessionStorage.getItem("fullname") || '')?.value}</p>
                        <p>کد ملی : {JSON.parse(sessionStorage.getItem("userInformation") || '')?.value.nationalCode}</p>
                        <p>تلفن همراه : {JSON.parse(sessionStorage.getItem("userInformation") || '')?.value.mobileNumber}</p>
                        {JSON.parse(sessionStorage.getItem("price") || '')?.value > 0 && <p>مبلغ پرداختی : {seperate(deposit.price)} تومان</p>}
                        {JSON.parse(sessionStorage.getItem("price") || '')?.value > 0 && <p>شماره پیگیری : {deposit.patientId}</p>}
                        <section style={{ display: 'flex', alignItems: 'center' }}>
                            <p>تلفن مرکز : </p>
                            <p>{departmentInfo && departmentInfo[0].TelDep}</p>
                        </section>
                        <p>آدرس مرکز : {departmentInfo && departmentInfo[0].AddressDep}</p>
                    </section>
                    <hr className='w-full mt-8' />
                    <section className='text-[#83878a] mt-8 text-[13px] px-3'>
                        {(roles.length > 0 && roles[0]?.DescriptionFollowUP) && parse(roles[0]?.DescriptionFollowUP)}
                    </section>
                </div>
                <ReactToPrint
                    content={reactToPrintContent}
                    documentTitle={JSON.parse(sessionStorage.getItem("fullname") || '')?.value}
                    onBeforePrint={() => setLoading(false)}
                    removeAfterPrint
                    onBeforeGetContent={() => setLoading(true)}
                    trigger={reactToPrintTrigger}
                />
            </>
                : <><p className='text-center text-red-500 font-bold'>پرداخت ناموفق</p><p className='mt-4'>نوبت انتخاب شده آزاد شده است. برای رزرو دوباره نوبت مجددا تلاش کنید.</p></>}

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
